/**
 * @component_Name : ForumsAndClassifiedsBody
 * @description : Here user can see list of forum titles and can add a new thread and also user can search the forum
 * @company : Photon
 * @author : Gobi
 **/
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../global/breadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { Link, useHistory } from "react-router-dom";
import CurrentLocation from "../global/currentLocation";
import DataGridPagination from "../global/dataGridPagination";
import Moment from "react-moment";
import { Accordion, Alert } from "react-bootstrap";
import NewThread from "./newThread";
import parse from "html-react-parser";
import { renderToString } from "react-dom/server";
import _ from "lodash";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";

const ForumsAndClassifiedsBody = (props) => {
  const history = useHistory();
  const [getPath, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(localStorage.getItem("ForumTabName")||"");
  const handlerTab = (params) => {
    setSelectedTab(params);
    setNewThreadActive(false);
    newThreadData === params && setNewThreadActive(true);
    localStorage.setItem("ForumTabName", params)//  = ;
    localStorage.removeItem("ForumPginationNumber");
    setPginationNumber(null);
  };
  const tokenId = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const [forumList, setLorumList] = useState([]);
  const [forumTypes, setForumTypes] = useState([]);

  const [loader, setLoader] = useState(false);
  const [newThreadActive, setNewThreadActive] = useState(false);
  const [newThreadData, setNewThreadData] = useState("");
  const [newThreadSubmitted, setNewThreadSubmitted] = useState(0);
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const [searchText, setSearchText] = useState("");
  const [subscriptionDetails, setsubscriptionDetails] = useState([]);
  const [pginationNumber, setPginationNumber] = useState(localStorage.getItem("ForumPginationNumber") && JSON.parse(localStorage.getItem("ForumPginationNumber"))[localStorage.getItem("ForumTabName")?.replace("-","_")] || null);
  //Defining Grid Column
  const columnsArray = [
    {
      key: "title",
      name: "Thread",
      width: "30%",
      cellClass: "enablesTextWrapping ",
      formatter(props) {
        return (
          <Link
            to={`${getPath}/${props.row.forum_type_title_en.replace(/\//g,'-')
              .toLowerCase()
              .replace(/ /g, "-")}/${props.row.id}/${props.row.title
              .toLowerCase()
              .replace(/ /g, "-").replace(/\//g,"-")}`}
          >
            <i className="fa fa-file-o"></i> {props.row.title}
          </Link>
        );
      },
    },
    {
      key: "user_fullname",
      name: "Created By",
      width: "20%",
    },
    {
      key: "comment_count",
      name: "Posts",
      headerCellClass: "grid_column_center",
      cellClass: "text-lg-center",
      width: "10%",
    },
    {
      key: "post_views",
      name: "Views",
      headerCellClass: "grid_column_center",
      cellClass: "text-lg-center",
      width: "10%",
    },
    {
      key: "lastPost",
      name: "Last Post",
      cellClass: "enablesTextWrapping ",
      width: "30%",
      formatter(props) {
        return parse(props?.row?.lastPost || "");
      },
    },
  ];
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    IsTokenChecker(tokenId) &&
      getSubscriptionDetails(accounts[0]?.localAccountId);
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [accounts, tokenId]);
  useEffect(() => {
    IsTokenChecker(tokenId) && getForumTabList();
  }, [tokenId]);
  
  useEffect(() => {
    IsTokenChecker(tokenId) && getAllForums();
  }, [newThreadSubmitted, tokenId]);

  const getForumTabList = async () =>{
    setLoader(true);
    await cmsDataCommonMethod(tokenId, accounts, "forum_types?select=*&is_active=eq.true&order=order_by.asc")
      .then((res) => {
        let response = res?.data?.data || [];
        console.log(response)
        setForumTypes(response);
          if (response.length > 0){
            if (selectedTab === ""){
              setSelectedTab(response[0]?.slug);
              localStorage.setItem("ForumTabName", response[0]?.slug);
            }            
          }       
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  }
  const getAllForums = async () => {
    setLoader(true);
    await cmsDataCommonMethod(tokenId, accounts, "v_forum_landing")
      .then((res) => {
        let response = res?.data?.data || [];
        if (response.length > 0) {
          const regex = /(<([^>]+)>)/gi;
          response = response.map((item) => {
            item.lastPost = `${item.last_comment_name || ""} ${
              item.last_comment_created_at
                ? `(${renderToString(
                    <Moment local utc format="MM/DD/YYYY hh:mm:ss A">
                      {item.last_comment_created_at}
                    </Moment>
                  )})`
                : "-"
            }`.replace(regex, "");
            item.lastPostMobile = item.lastPost.replace(regex, "");
            item.forum_type_title_en = item.forum_type_title_en?.trim();
            item.slugId = item.forum_type_title_en?.replace(/ /g, "-")?.toLowerCase();
            return item;
          });
          // const key = "forum_type_title_en";
          // const unique = [
          //   ...new Map(response.map((item) => [item[key], item])).values(),
          // ]?.sort((a, b) => a.forum_type_order_by - b.forum_type_order_by);
          // setForumTypes(unique);
          // if (selectedTab === ""){
          //   setSelectedTab(unique[0]?.slugId);
          //   localStorage.setItem("ForumTabName", unique[0]?.slugId);
          // }
          setLorumList(response || []);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const getSubscriptionDetails = async (userID) => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `forum_subscriptions?user_id=eq.${userID}`
    )
      .then((res) => {
        let response = res?.data?.data || [];
        if (response.length > 0) {
          setsubscriptionDetails(response);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const newThreadShowEvent = (item) => {
    setNewThreadActive(true);
    setNewThreadData(item);
  };
  const closeNewThread = () => {
    setNewThreadActive(false);
    setNewThreadData("");
  };
  const setNewThreadSaved = (data) => {
    setNewThreadSubmitted(data);
    setAlertOpen({
      isActive: true,
      message: moreLangs[i18n.resolvedLanguage].forum_page.save_success_msg,
    });
  };
  const handleSearchSubmit = (event) => {
    if (searchText !== "") {
      history.push(`/community/forums/search?q=${searchText}`);
    }
    event.preventDefault();
  };
  const subscribeToForum = (obj) => {
    commonAjaxCall(
      "forum-subscriptions",
      "post",
      {
        user_id: accounts[0].localAccountId,
        forum_type_id: obj.id,
      },
      (res) => {
        setLoader(false);
        if (res?.data?.status === "success") {
          let subscription = subscriptionDetails;
          subscription.push(res?.data?.item);
          setsubscriptionDetails([...subscription]);
          setAlertOpen({
            isActive: true,
            message: `${
              moreLangs[i18n.resolvedLanguage].forum_page.subscrib_success_msg
            } ${obj["title_" + i18n.resolvedLanguage]}`,
          });
          // setTimeout(() => {
          //   setAlertOpen({isActive:false,message:""});
          // }, 4000);
        }
      }
    );
  };

  const unSubscribeToForum = (obj) => {
    let SelectedForum = subscriptionDetails.filter(
      (item) =>
        item.forum_type_id === obj.id &&
        item.user_id === accounts[0].localAccountId
    );
    commonAjaxCall(
      `forum-subscriptions/${SelectedForum[0]?.id}?userid=${accounts[0].localAccountId}`,
      "delete",
      null,
      (res) => {
        setLoader(false);
        if (res?.data?.status === "success") {
          let subscription = subscriptionDetails;
          _.remove(subscription, {
            id: SelectedForum[0].id,
          });
          setsubscriptionDetails([...subscription]);
          setAlertOpen({
            isActive: true,
            message: `${
              moreLangs[i18n.resolvedLanguage].forum_page.unsubscrib_success_msg
            } ${obj["title_" + i18n.resolvedLanguage]}`,
          });
          // setTimeout(() => {
          //   setAlertOpen({isActive:false,message:""});
          // }, 4000);
        }
      }
    );
  };

  const commonAjaxCall = async (url, method, data, successMethod) => {
    // return;
    setLoader(true);
    await cmsDataCommonMethod(tokenId, accounts, url, method, data)
      .then(successMethod)
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const pagenationEvent = (data) =>{
    localStorage.setItem("ForumPginationNumber", `{"${selectedTab.replace("-","_")}" : ${data}}`);
    setPginationNumber(data);
  }
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}

      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className=" col-12 col-lg-12 d-none d-lg-block">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div
              className={`accordion-body mt-3 p-0 ${
                newThreadActive && "disableTab"
              }`}
            >
              {alertOpen.isActive && (
                <Alert
                  className="mt-m-5"
                  transition={true}
                  variant="success"
                  onClose={() => setAlertOpen({ isActive: false, message: "" })}
                  dismissible
                >
                  <b>{alertOpen.message}</b>
                </Alert>
              )}
              {/*<!-- Tabs for Forum List -->*/}
              {window.innerWidth < 773 && forumTypes.length > 4 ? 
              <>
              <label className="forum-category-slect-label">Select Forum Category</label>
              <select className="forum-category-slect form-control mb-4 form-select" value={selectedTab} onChange={(e) => handlerTab(e.target.value)}>
                {forumTypes?.map((item, index) => (
                    <option key={index} value={item.slug}>{item[`title_${i18n.resolvedLanguage}`]}</option>
                  ))}
              </select></>
              :
              <ul
                className={`nav nav-pills mt-5 mt-lg-4 mb-3 bordered_nav_tabs sm-forums-tabs thread-tabs tab-list-${forumTypes.length}`}
                id="pills-tab"
                role="tablist"
              >
                {forumTypes?.map((item, index) => (
                  <li className="nav-item" key={index} role="presentation">
                    <button
                      className={`nav-link ${
                        selectedTab === item.slug ? " active" : ""
                      }`}
                      id="pills-receipts-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-receipts"
                      type="button"
                      role="tab"
                      aria-controls="pills-receipts"
                      aria-selected={selectedTab === item.slug ? true : false}
                      onClick={() => handlerTab(item.slug)}
                    >
                      {item[`title_${i18n.resolvedLanguage}`]}
                    </button>
                  </li>
                ))}
              </ul>
              
              }

              <div className="tab-content" id="pills-tabContent">
                {/*<!-- Forum Tab details -->*/}
                {!newThreadActive && (
                  <>
                    {forumTypes?.map((tabName, index) => {
                      let forumData = forumList?.filter(
                        (item) =>
                          item.slugId.toLowerCase() === tabName.slug.toLowerCase()
                      );
                      return (
                        <div
                          key={index}
                          className={`tab-pane fade ${
                            selectedTab === tabName.slug ? " show active" : ""
                          }`}
                          id="pills-receipts"
                          role="tabpanel"
                          aria-labelledby="pills-receipts-tab"
                        >
                          <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
                            <div className="row mt-2">
                              <div className="col-12">
                                <h3 className="mob-fw-bold">
                                  {
                                    tabName[
                                      `title_${i18n.resolvedLanguage}`
                                    ]
                                  }
                                </h3>
                              </div>
                              <div className="col-12">
                                <div className="float-end mb-2">
                                  <div className="row">
                                    <div className="col-auto pe-2  mb-2">
                                      <form onSubmit={handleSearchSubmit}>
                                        <span className="input-group border-1 border-radius-6">
                                          <span
                                            className="input-group-text border-0 white_bg"
                                            id="basic-addon1"
                                          >
                                            <i
                                              className="fa fa-search blue_color text-18"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <input
                                            onChange={(e) =>
                                              setSearchText(e.target.value)
                                            }
                                            type="text"
                                            name="q"
                                            className="form-control border-0 py-2 left-space-0"
                                            placeholder={
                                              moreLangs[i18n.resolvedLanguage]
                                                .forum_page.search_forum
                                            }
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                          />
                                        </span>
                                      </form>
                                    </div>
                                    <div className="col-auto pe-2 mb-2">
                                      <span>
                                        <button
                                          onClick={() => {
                                            if (
                                              subscriptionDetails?.filter(
                                                (e) =>
                                                  e.forum_type_id ===
                                                  tabName.id
                                              ).length === 0
                                            )
                                              subscribeToForum(tabName);
                                            else unSubscribeToForum(tabName);
                                          }}
                                          className="btn btn-primary transparent_btn fw-bold"
                                        >
                                          {subscriptionDetails?.filter(
                                            (e) =>
                                              e.forum_type_id ===
                                              tabName.id
                                          ).length === 0
                                            ? moreLangs[i18n.resolvedLanguage]
                                                .forum_page.subscribe_to_forum
                                            : moreLangs[i18n.resolvedLanguage]
                                                .forum_page
                                                .unsubscribe_to_forum}
                                        </button>
                                      </span>
                                    </div>
                                    <div className="col-auto pe-2 mb-2">
                                      <span>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => {
                                            newThreadShowEvent(tabName);
                                          }}
                                        >
                                          {
                                            moreLangs[i18n.resolvedLanguage]
                                              .forum_page.new_thread
                                          }
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {selectedTab === tabName.slug &&  !displayAccordian && forumData.length > 0 && (
                              <div
                                key={index}
                                className={`
                            hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
                              >
                                {/* {console.log(pginationNumber ? {"forcePage":pginationNumber} : undefined)} */}
                                <DataGridPagination
                                  uniqueIdentifier="id"
                                  gridData={forumData}
                                  columns={columnsArray}
                                  isPagination={true}
                                  itemsPerPage={10}
                                  columnResize={false}
                                  classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                                  dynamicHeight={55}
                                  pageNumber = {pginationNumber ? pginationNumber : undefined}
                                  pagenationEvent={pagenationEvent}
                                />
                              </div>
                            )}
                            {forumData.length == 0 && (
                              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                                <i className="fa fa-info-circle pe-2"></i>
                                {
                                  moreLangs[i18n.resolvedLanguage]
                                    .no_record_found
                                }
                              </div>
                            )}
                            {forumData.length > 0 && (
                              <div
                                className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
                              >
                                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                                  <Accordion defaultActiveKey="0">
                                    {forumData.length > 0 &&
                                      Object.values(forumData).map(
                                        (data, index) => {
                                          return (
                                            <Fragment key={data.id}>
                                              <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                  <div>
                                                    Thread: {data.title},
                                                    Created by:
                                                    {data.user_fullname}
                                                  </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="tbody">
                                                    <div className="tr">
                                                      <div className="td"></div>
                                                      <div
                                                        className="td"
                                                        data-header="Thread:"
                                                      >
                                                        <Link
                                                          to={`${getPath}/${data.forum_type_title_en.toLowerCase().replace(/ /g,"-")}/${data.id}/${data.title.toLowerCase().replace(/ /g,"-").replace(/\//g,"-")}`}>
                                                          <i className="fa fa-file-o"></i>{" "}
                                                          {data.title}
                                                        </Link>
                                                      </div>
                                                      <div
                                                        className="td"
                                                        data-header="Created By:"
                                                      >
                                                        <span>
                                                          {data.user_fullname ||
                                                            "-"}
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="td"
                                                        data-header="Posts:"
                                                      >
                                                        {data.comment_count ||
                                                          "-"}
                                                      </div>
                                                      <div
                                                        className="td"
                                                        data-header="Views:"
                                                      >
                                                        {data.post_views || "-"}
                                                      </div>
                                                      <div
                                                        className="td"
                                                        data-header="Last Post:"
                                                      >
                                                        {data?.lastPostMobile ||
                                                          "-"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                  </Accordion>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                {newThreadActive && (
                  <NewThread
                    data={newThreadData}
                    cancel={closeNewThread}
                    handleRefreshData={setNewThreadSaved}
                    handleRefreshDataCount={newThreadSubmitted}
                  />
                )}
              </div>
              {/*<!-- Tabs for Delivery data ends -->*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumsAndClassifiedsBody;
