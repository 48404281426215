/**
 * @component_Name : PaymentEstimatorCalculationDetail
 * @description : Payment Estimator Calculation
 * @company : Photon
 * @author : Nivethithan Kannan/02-01-2024
 **/

import React, { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../../global/currentLocation";
import ExportFile from "../../../exportFile/exportFile";
import { PaymentEstimatorPdfFile as PdfFile } from "../../../exportFile/pdfFile";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsStringChecker,
  HandleErrorTrackAndToast,
  compareString,
  IsObjectChecker,
} from "../../../../utils/helper";
import { Accordion, Table } from "react-bootstrap";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import {
  IsArrayElementsTrue,
  // StringFinder,
  EstimateNumberValidation,
  EstimateNumberFormater,
  ExportNumberConverter,
  GetMonthYear,
} from "./paymentEstimatorFunctionsCommon";
const PaymentEstimatorCalculationDetail = (props) => {
  const {
    calculateEstimate,
    EstimateRawInput = {},
    contractOptions = [],
  } = props;

  const {
    contract = null,
    estimateName: original_estimate_name = null,
    estimateLabel = null,
    payment_mappings = [],
    additional_year = {},
  } = EstimateRawInput;
  // console.log(EstimateRawInput);

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  function GetEstimateName(name, label) {
    try {
      if (IsStringChecker(name)) {
        if (IsStringChecker(label)) {
          return `${label} : ${name}`;
        }
        return name;
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  }

  // General
  const [active_accordion, set_active_accordion] = useState(null);
  const estimateName = GetEstimateName(original_estimate_name, estimateLabel);

  function GetIncentive(barrels, incentiveRateOrDollars, obj) {
    const { incentiveRate, incentiveDollars } = obj;
    try {
      if (incentiveRateOrDollars == true) {
        const incentiveTemp = parseFloat(incentiveRate);
        return {
          incentive_rate: incentiveTemp,
          incentive: incentiveTemp * barrels,
        };
      } else {
        return { incentive_rate: 0, incentive: parseFloat(incentiveDollars) };
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { incentive_rate: 0, incentive: 0 };
    }
  }

  function GetParentChildIndex(index, parentIndex, childIndex) {
    const newChild = childIndex + index;
    if (newChild <= 3) {
      return { parent: parentIndex, child: newChild };
    } else {
      return { parent: parentIndex + 1, child: newChild - 4 };
    }
  }

  function GetMaxRowSpan(arr = []) {
    var temp = 4;
    arr.forEach((li) => {
      const tem = li?.deferrel_payouts?.length || 0;
      const len = tem ? temp + (tem - 1) : temp;
      if (temp <= len) {
        temp = len;
      }
    });
    return temp;
  }

  function HandleAccordionClick(accordion_selected) {
    set_active_accordion((prev) => {
      if (compareString(accordion_selected, prev)) {
        return "";
      } else {
        return accordion_selected;
      }
    });
  }

  //Overall Estimate Labels
  const Overall_estimate_payment_type_order = [
    {
      id: 1,
      order: 1,
      oKey: "continuous_pool_distribution",
      key: "continuous_pool_distribution",
      rawKey: "raw_cpd",
      oSubKey: "payment_rate",
      subKey: "payment_rate",
      rawSubKey: "raw_payment_rate",
      type: "add",
      label: "Continuous Pool Distribution",
    },
    {
      id: 2,
      order: 2,
      oKey: "deferred_amount",
      key: "deferred_amount",
      rawKey: "raw_deferred_amount",
      oSubKey: "",
      subKey: "",
      rawSubKey: "",
      type: "sub",
      label: "Deferment Taken",
    },
    {
      id: 3,
      order: 3,
      oKey: "incentive",
      key: "incentive",
      rawKey: "raw_incentive",
      oSubKey: "incentive_rate",
      subKey: "incentive_rate",
      rawSubKey: "raw_incentive_rate",
      type: "add",
      label: "Incentives",
    },
    {
      id: 4,
      order: 4,
      oKey: "deferred_payout",
      key: "deferred_payout",
      rawKey: "raw_deferred_payout",
      oSubKey: "",
      subKey: "",
      rawSubKey: "",
      type: "add",
      label: "Deferred Payout",
    },
    {
      id: 5,
      order: 5,
      oKey: "total_interest_deferred",
      key: "total_interest_deferred",
      rawKey: "raw_total_interest_deferred",
      oSubKey: "",
      subKey: "",
      rawSubKey: "",
      type: "add",
      label: "Interest for Deferred Payout",
    },
    {
      id: 6,
      order: 6,
      oKey: "are_redemption",
      key: "are_redemption",
      rawKey: "raw_are_redemption",
      oSubKey: "",
      subKey: "",
      rawSubKey: "",
      type: "add",
      label: "ARE Redemption",
    },
    {
      id: 7,
      order: 7,
      oKey: "are_issuance",
      key: "are_issuance",
      rawKey: "raw_are_issuance",
      oSubKey: "are_rate",
      subKey: "are_rate",
      rawSubKey: "raw_are_rate",
      type: "add",
      label: "ARE Issuance",
    },
    {
      id: 8,
      order: 8,
      oKey: "cash_in_lieu_of_stock",
      key: "cash_in_lieu_of_stock",
      rawKey: "raw_cash_in_lieu_of_stock",
      oSubKey: "",
      subKey: "",
      rawSubKey: "",
      type: "add",
      label: "Common Stock Retain Issuance",
    },
  ];
  const export_cash_estimate_format = [
    {
      index: 1,
      format: "#,##0.00_);(#,##0.00)",
    },
    {
      index: 3,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 4,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 5,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 6,
      format: "$#,##0.00_);($#,##0.00)",
    },
  ];
  const export_deferred_estimate_format = [
    {
      index: 2,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 5,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 6,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 7,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 8,
      format: "$#,##0.00_);($#,##0.00)",
    },
  ];
  const export_interest_estimate_format = [
    {
      index: 5,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 6,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 7,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 8,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 9,
      format: "$#,##0.00_);($#,##0.00)",
    },
  ];
  const export_overall_estimate_format = [
    {
      index: 1,
      format: "#,##0.00_);(#,##0.00)",
    },
    {
      index: 4,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 5,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 6,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 7,
      format: "$#,##0.00_);($#,##0.00)",
    },
    {
      index: 8,
      format: "$#,##0.00_);($#,##0.00)",
    },
  ];

  //Payment Estimate
  const cash_payment_title = "cash_payment_estimate";
  const deferred_payment_title = "deferred_payment_estimate";
  const interest_payment_title = "interest_payment_estimate";
  const [
    loading_deferred_payment_estimate,
    set_loading_deferred_payment_estimate,
  ] = useState(false);
  const [final_table_estimate, set_final_table_estimate] = useState([]);
  const [final_payments_estimate, set_final_payments_estimate] = useState([]);
  const [deferred_payment_estimate, set_deferred_payment_estimate] = useState(
    []
  );
  const [export_cash_payment_estimate, set_export_cash_payment_estimate] =
    useState([]);
  const [
    export_deferred_payment_estimate,
    set_export_deferred_payment_estimate,
  ] = useState([]);
  const [
    export_interest_payment_estimate,
    set_export_interest_payment_estimate,
  ] = useState([]);
  const [export_overall_payment_estimate, set_export_overall_payment_estimate] =
    useState([]);
  const [pdf_cash_payment_estimate, set_pdf_cash_payment_estimate] = useState(
    []
  );
  const [pdf_deferred_payment_estimate, set_pdf_deferred_payment_estimate] =
    useState([]);
  const [pdf_interest_payment_estimate, set_pdf_interest_payment_estimate] =
    useState([]);
  const [pdf_overall_payment_estimate, set_pdf_overall_payment_estimate] =
    useState([]);

  function EstimatedOverallTotal(arr = []) {
    try {
      if (IsArrayNotEmpty(arr)) {
        const total_payment = arr.reduce(
          (acc, li) => (acc += li.year_total_payment),
          0
        );
        return `$${EstimateNumberFormater(total_payment)}`;
      } else {
        return "-";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-";
    }
  }

  function DisplayAdditionalYearReport(payments_arr) {
    try {
      if (IsObjectChecker(additional_year)) {
        const { payments_pending, payments } = additional_year || {};
        const display = payments_pending && IsArrayNotEmpty(payments);
        if (display) {
          const new_payments_arr = [...payments_arr];
          new_payments_arr.unshift(additional_year);
          return new_payments_arr;
        }
        return payments_arr;
      }
      return payments_arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return payments_arr;
    }
  }

  function CashAndDeferredPaymentReport() {
    try {
      if (IsArrayNotEmpty(payment_mappings)) {
        const full_payment_mappings =
          DisplayAdditionalYearReport(payment_mappings);
        return full_payment_mappings.map((item) => {
          const {
            // cropyear,
            barrels: barrelsStr,
            incentiveRateOrDollars,
            interestRate,
            isDeferred: is_deferred,
            payments,
          } = item;
          const barrels = parseFloat(barrelsStr.replace(/,/g, ""));
          const deferrel_interest = parseFloat(interestRate);
          const quarterly_payments = payments.map((list, index) => {
            const {
              rate: rateStr,
              // month,
              isDeferredPeriod,
              deferrelPeriod,
              deferrelPercent,
              // contractOrder = null,
              // SA_Order,
            } = list;
            // function SettlementYear(mon, year, contract_order, order) {
            //   if (contract_order == null) {
            //     return mon;
            //   } else {
            //     if (contract_order) {
            //       const ord = parseInt(order);
            //       if (ord <= 2) {
            //         return GetMonthYear(month, year);
            //       } else {
            //         const num = parseInt(year) + 1;
            //         return GetMonthYear(month, num);
            //       }
            //     } else {
            //       const num = parseInt(year) + 1;
            //       return GetMonthYear(month, num);
            //     }
            //   }
            // }
            // const payment_month = SettlementYear(
            //   month,
            //   cropyear,
            //   contractOrder,
            //   SA_Order
            // );
            const rate = parseFloat(rateStr);
            const continuous_pool_distribution = barrels * rate;
            const { incentive = 0, incentive_rate = 0 } = GetIncentive(
              barrels,
              incentiveRateOrDollars,
              list
            );
            const total = incentive + continuous_pool_distribution;
            return {
              ...list,
              order: index + 1,
              payment_rate: rate,
              // payment_month,
              incentive,
              incentive_rate,
              continuous_pool_distribution,
              cash_payment: total,
              is_deferred_period: isDeferredPeriod,
              deferrel_period: parseFloat(deferrelPeriod),
              deferrel_percent: parseFloat(deferrelPercent),
            };
          });
          return {
            ...item,
            deferrel_interest,
            barrels,
            is_deferred,
            payments: quarterly_payments,
          };
        });
      } else {
        return [];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function InterestPaymentReport() {
    try {
      const deferred_payment_data = CashAndDeferredPaymentReport();
      const dummy_deferrel_payouts = [
        {
          deferrel_month: "-",
          deferrel_year: "-",
          deferrel_label: "-",
          amount: 0,
          period: 0,
          percent: 0,
          interest_rate: 0,
          interest: 0,
          total: 0,
        },
      ];
      if (IsArrayNotEmpty(deferred_payment_data)) {
        const calculated_deferred_data = deferred_payment_data.map((li) => {
          const returnObj = { ...li };
          const { payments, is_deferred } = returnObj;
          if (is_deferred) {
            const calculate_deferred = payments.map((item) => {
              const {
                continuous_pool_distribution,
                is_deferred_period,
                deferrel_percent,
              } = item;
              if (is_deferred_period) {
                const deferred_amount =
                  (deferrel_percent / 100) * continuous_pool_distribution;
                const remaining_amount =
                  continuous_pool_distribution - deferred_amount;
                return {
                  ...item,
                  deferred_amount,
                  remaining_amount,
                  deferrel_payouts: dummy_deferrel_payouts,
                };
              } else {
                return {
                  ...item,
                  deferred_amount: 0,
                  remaining_amount: continuous_pool_distribution,
                  deferrel_payouts: dummy_deferrel_payouts,
                };
              }
            });
            returnObj["payments"] = calculate_deferred;
            return returnObj;
          } else {
            const calculate_deferred = payments.map((item) => {
              return {
                ...item,
                deferred_amount: 0,
                remaining_amount: item?.continuous_pool_distribution,
                deferrel_payouts: dummy_deferrel_payouts,
              };
            });
            returnObj["payments"] = calculate_deferred;
            return returnObj;
          }
        });
        if (calculated_deferred_data) {
          const calculated_deferred_payouts = [...calculated_deferred_data];
          calculated_deferred_data.forEach((list, parentIndex) => {
            const { cropyear, payments, deferrel_interest } = list;
            payments.forEach((item, childIndex) => {
              const {
                month,
                is_deferred_period,
                deferrel_period,
                deferrel_percent,
                deferred_amount,
              } = item;
              if (is_deferred_period && deferrel_period != 0) {
                const deferrel_label = GetMonthYear(month, cropyear);
                const interest_rate =
                  (deferrel_period / 12) * (deferrel_interest / 100);
                const interest = interest_rate * deferred_amount;
                const total = interest + deferred_amount;
                const setObj = {
                  deferrel_month: month,
                  deferrel_year: cropyear,
                  deferrel_label,
                  amount: deferred_amount,
                  period: deferrel_period,
                  percent: deferrel_percent,
                  interest_rate,
                  interest,
                  total,
                };
                const index = deferrel_period / 3;
                const { parent, child } = GetParentChildIndex(
                  index,
                  parentIndex,
                  childIndex
                );
                const child_deferrel_payouts = _.get(
                  calculated_deferred_payouts,
                  `${parent}.payments.${child}.deferrel_payouts`,
                  []
                );
                const found = child_deferrel_payouts.find(
                  (li) => li.deferrel_label == "-"
                );
                if (found) {
                  _.set(
                    calculated_deferred_payouts,
                    `${parent}.payments.${child}.deferrel_payouts`,
                    [{ ...setObj }]
                  );
                } else {
                  child_deferrel_payouts.push({ ...setObj });
                  _.set(
                    calculated_deferred_payouts,
                    `${parent}.payments.${child}.deferrel_payouts`,
                    [...child_deferrel_payouts]
                  );
                }
              }
            });
          });
          return calculated_deferred_payouts;
        } else {
          return [];
        }
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function PaymentCalculation() {
    try {
      const deferred_payment_data = InterestPaymentReport().filter(
        (li) => li?.cropyear
      );
      if (IsArrayNotEmpty(deferred_payment_data)) {
        const deferrel_payout_arr = deferred_payment_data.map((list) => {
          const { payments = [] } = list;
          if (IsArrayNotEmpty(payments)) {
            const masterList = payments.map((item) => {
              const {
                deferrel_payouts = [],
                remaining_amount,
                incentive,
              } = item;
              const calculatedObj = deferrel_payouts.reduce(
                (acc, li) => {
                  const interest = li?.interest || 0;
                  // const interest_rate = li?.interest_rate || 0;
                  const amount = li?.amount || 0;
                  const total = li?.total || 0;

                  acc.total_interest += interest;
                  // acc.total_interest_rate += interest_rate;
                  acc.deferred_payout += amount;
                  acc.deferred_payout_interest += total;
                  return acc;
                },
                {
                  total_interest: 0,
                  // total_interest_rate: 0,
                  deferred_payout: 0,
                  deferred_payout_interest: 0,
                }
              );

              const {
                total_interest = 0,
                // total_interest_rate = 0,
                deferred_payout = 0,
                deferred_payout_interest = 0,
              } = calculatedObj;
              const remCPD_deferred = remaining_amount + deferred_payout;
              const remCPD_deferred_interest =
                remaining_amount + deferred_payout_interest;
              const remIncentiveCPD_deferred_interest =
                remCPD_deferred_interest + incentive;
              return {
                ...item,
                total_interest_deferred: total_interest,
                // total_interest_rate_deferred: total_interest_rate,
                deferred_payout,
                deferred_payout_interest,
                remCPD_deferred,
                remCPD_deferred_interest,
                remIncentiveCPD_deferred_interest,
              };
            });
            const year_total_payment = masterList.reduce((acc, li) => {
              const total_payment = li?.remIncentiveCPD_deferred_interest || 0;
              acc += total_payment;
              return acc;
            }, 0);
            return {
              ...list,
              payments: masterList,
              year_total_payment,
            };
          } else {
            return list;
          }
        });
        return deferrel_payout_arr;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function GetFinalPaymentsData(payment_mappings) {
    const mergerd_payment_mappings = payment_mappings.map((item) => {
      const {
        payments,
        are_data,
        stock_retain_data,
        consider_are_stock_data = false,
      } = item;
      if (consider_are_stock_data) {
        const is_are_empty = _.isEmpty(are_data),
          is_stock_retain_empty = _.isEmpty(stock_retain_data);

        const {
          are_data: check_are_data,
          are_region,
          are_issuance_month,
          are_issuance_year,
          are_redemption_month,
          are_redemption_year,
          are_issuance,
          are_redemption,
          are_rate,
        } = are_data;

        const {
          stock_data,
          // stock_retain_region,
          stock_retain_month,
          stock_retain_year,
          cash_in_lieu_of_stock,
        } = stock_retain_data;

        var quarterly_payments = [...payments];

        if (!is_are_empty) {
          if (check_are_data) {
            const redemption = {
              key: payments.length,
              order: payments.length + 1,
              month: are_redemption_month,
              are_redemption_month,
              are_redemption_year,
              payment_month: GetMonthYear(
                are_redemption_month,
                are_redemption_year
              ),
              are_redemption,
              remIncentiveCPD_deferred_interest: are_redemption
                ? parseFloat(are_redemption.replace(/,/g, ""))
                : 0,
            };
            const issuance = {
              key: payments.length + 1,
              order: payments.length + 2,
              month: are_issuance_month,
              are_issuance_month,
              are_issuance_year,
              payment_month: GetMonthYear(
                are_issuance_month,
                are_issuance_year
              ),
              are_issuance,
              are_rate,
              remIncentiveCPD_deferred_interest: are_issuance
                ? parseFloat(are_issuance.replace(/,/g, ""))
                : 0,
            };
            if (are_region) {
              quarterly_payments.push(issuance);
              quarterly_payments.push(redemption);
            } else {
              quarterly_payments.splice(2, 0, redemption);
              quarterly_payments.push(issuance);
            }
          }
        }

        if (!is_stock_retain_empty) {
          if (stock_data) {
            const stock_retain = {
              key: payments.length + 2,
              order: payments.length + 3,
              month: stock_retain_month,
              stock_retain_month,
              stock_retain_year,
              payment_month: GetMonthYear(
                stock_retain_month,
                stock_retain_year
              ),
              cash_in_lieu_of_stock,
              remIncentiveCPD_deferred_interest: cash_in_lieu_of_stock
                ? parseFloat(cash_in_lieu_of_stock.replace(/,/g, ""))
                : 0,
            };
            quarterly_payments.push(stock_retain);
          }
        }

        return {
          ...item,
          payments: quarterly_payments,
        };
      } else {
        return { ...item };
      }
    });

    return mergerd_payment_mappings.map((item) => {
      const { payments } = item;
      const year_total_payment = payments.reduce((acc, li) => {
        const total_payment = li?.remIncentiveCPD_deferred_interest || 0;
        acc += total_payment;
        return acc;
      }, 0);
      return { ...item, year_total_payment };
    });
  }

  function PaymentReport() {
    const deferred_payment_data = PaymentCalculation();
    const access =
      IsArrayNotEmpty(deferred_payment_data) &&
      deferred_payment_data.every((li) => IsArrayNotEmpty(li?.payments));
    if (access) {
      set_deferred_payment_estimate(deferred_payment_data);

      const { cashExportData = [], cashPdfData = [] } =
        GenerateExportQuarterlyPayment(deferred_payment_data);
      const { deferredExportData = [], deferredPdfData = [] } =
        GenerateExportDeferredPayment(deferred_payment_data);
      const { interestExportData = [], interestPdfData = [] } =
        GenerateExportInterestPayment(deferred_payment_data);

      set_export_cash_payment_estimate(cashExportData);
      set_export_deferred_payment_estimate(deferredExportData);
      set_export_interest_payment_estimate(interestExportData);

      set_pdf_cash_payment_estimate(cashPdfData);
      set_pdf_deferred_payment_estimate(deferredPdfData);
      set_pdf_interest_payment_estimate(interestPdfData);

      const final_payments_data = GetFinalPaymentsData(deferred_payment_data);
      const final_access =
        IsArrayNotEmpty(final_payments_data) &&
        final_payments_data.every((li) => IsArrayNotEmpty(li?.payments));
      if (final_access) {
        set_final_payments_estimate(final_payments_data);

        const { exportData = [], pdfData = [] } =
          GenerateExportOverallPayment(final_payments_data);

        set_export_overall_payment_estimate(exportData);

        set_pdf_overall_payment_estimate(pdfData);

        const final_table_data = DisplayRowsForOverallEstimate(
          final_payments_data,
          Overall_estimate_payment_type_order
        );
        set_final_table_estimate(final_table_data);
      } else {
        set_final_payments_estimate([]);
        set_final_table_estimate([]);
      }
    } else {
      EmptyEstimates();
    }
  }

  function GenerateExportQuarterlyPayment(data) {
    if (IsArrayNotEmpty(data)) {
      const cashExportData = [],
        cashPdfData = [];
      for (const item of data) {
        const { cropyear, barrels, payments } = item;
        // const barrels = EstimateNumberFormater(raw_barrels);
        if (IsArrayNotEmpty(payments)) {
          for (const list of payments) {
            const {
              month,
              payment_rate,
              continuous_pool_distribution,
              incentive,
              cash_payment,
            } = list;
            // const cash_payment = EstimateNumberFormater(raw_cash_payment);
            // const incentive = EstimateNumberFormater(raw_incentive);
            // const continuous_pool_distribution = EstimateNumberFormater(raw_cpd);
            cashExportData.push({
              "Crop Year": `${cropyear}`,
              "Estimated Barrels": ExportNumberConverter(barrels),
              Month: month,
              "Estimated Payment Rate": ExportNumberConverter(payment_rate),
              "Estimated Continuous Pool Payment": ExportNumberConverter(
                continuous_pool_distribution
              ),
              "Estimated Incentives": ExportNumberConverter(incentive),
              "Estimated Total Payment": ExportNumberConverter(cash_payment),
            });
            cashPdfData.push({
              "Crop Year": `${cropyear}`,
              "Estimated Barrels": `$${EstimateNumberFormater(barrels)}`,
              Month: month,
              "Estimated Payment Rate": `$${EstimateNumberFormater(
                payment_rate
              )}`,
              "Estimated Continuous Pool Payment": `$${EstimateNumberFormater(
                continuous_pool_distribution
              )}`,
              "Estimated Incentives": `$${EstimateNumberFormater(incentive)}`,
              "Estimated Total Payment": `$${EstimateNumberFormater(
                cash_payment
              )}`,
            });
          }
        }
      }
      return { cashExportData, cashPdfData };
    } else {
      return { cashExportData: [], cashPdfData: [] };
    }
  }

  function GenerateExportDeferredPayment(data) {
    if (IsArrayNotEmpty(data)) {
      const deferredExportData = [],
        deferredPdfData = [];
      for (const item of data) {
        const { cropyear, payments } = item;
        if (IsArrayNotEmpty(payments)) {
          for (const list of payments) {
            const {
              month,
              continuous_pool_distribution,
              deferrel_period,
              deferrel_percent,
              deferred_amount,
              remaining_amount,
              deferred_payout,
              remCPD_deferred,
            } = list;
            deferredExportData.push({
              "Crop Year": `${cropyear}`,
              Month: month,
              "Estimated Continuous Pool Payment": ExportNumberConverter(
                continuous_pool_distribution
              ),
              "Estimated Deferrel Period (months)": deferrel_period,
              "Estimated Deferrel %": deferrel_percent,
              "Estimated Amount of Payment Deferred":
                ExportNumberConverter(deferred_amount),
              "Estimated Net Payment after Deferrel":
                ExportNumberConverter(remaining_amount),
              "Estimated Deferred Payout":
                ExportNumberConverter(deferred_payout),
              "Estimated Total Payment": ExportNumberConverter(remCPD_deferred),
            });
            deferredPdfData.push({
              "Crop Year": `${cropyear}`,
              Month: month,
              "Estimated Continuous Pool Payment": `$${EstimateNumberFormater(
                continuous_pool_distribution
              )}`,
              "Estimated Deferrel Period (months)": deferrel_period,
              "Estimated Deferrel %": deferrel_percent,
              "Estimated Amount of Payment Deferred": `$${EstimateNumberFormater(
                deferred_amount
              )}`,
              "Estimated Net Payment after Deferrel": `$${EstimateNumberFormater(
                remaining_amount
              )}`,
              "Estimated Deferred Payout": `$${EstimateNumberFormater(
                deferred_payout
              )}`,
              "Estimated Total Payment": `$${EstimateNumberFormater(
                remCPD_deferred
              )}`,
            });
          }
        }
      }
      return { deferredExportData, deferredPdfData };
    } else {
      return { deferredExportData: [], deferredPdfData: [] };
    }
  }

  function GenerateExportInterestPayment(data) {
    if (IsArrayNotEmpty(data)) {
      const interestExportData = [],
        interestPdfData = [];
      for (const item of data) {
        const { cropyear, payments, deferrel_interest } = item;
        if (IsArrayNotEmpty(payments)) {
          for (const list of payments) {
            const {
              month,
              deferrel_period,
              deferrel_payouts,
              total_interest_deferred,
              deferred_payout_interest,
            } = list;
            if (IsArrayNotEmpty(deferrel_payouts)) {
              for (const it of deferrel_payouts) {
                const { deferrel_label, amount, interest_rate, interest } = it;
                const new_deferrel_label = deferrel_label?.split("-")?.[0] || "-";
                interestExportData.push({
                  "Crop Year": `${cropyear}`,
                  Month: month,
                  "Estimated Deferrel Period (months)": deferrel_period,
                  "Estimated Interest Rate %": deferrel_interest,
                  "Estimated Deferred Payment From": new_deferrel_label,
                  "Estimated Deferred Amount": ExportNumberConverter(amount),
                  "Estimated Calculated Inerest Rate":
                    interest_rate <= 0
                      ? 0.0
                      : ExportNumberConverter(interest_rate),
                  "Estimated Interest": ExportNumberConverter(interest),
                  "Estimated Total Interest": ExportNumberConverter(
                    total_interest_deferred
                  ),
                  "Estimated Deferred Payout With Interest":
                    ExportNumberConverter(deferred_payout_interest),
                });
                interestPdfData.push({
                  "Crop Year": `${cropyear}`,
                  Month: month,
                  "Estimated Deferrel Period (months)": deferrel_period,
                  "Estimated Interest Rate %": deferrel_interest,
                  "Estimated Deferred Payment From": new_deferrel_label,
                  "Estimated Deferred Amount": `$${EstimateNumberFormater(
                    amount
                  )}`,
                  "Estimated Calculated Inerest Rate":
                    interest_rate <= 0
                      ? `$0.00`
                      : `$${EstimateNumberFormater(interest_rate)}`,
                  "Estimated Interest": `$${EstimateNumberFormater(interest)}`,
                  "Estimated Total Interest": `$${EstimateNumberFormater(
                    total_interest_deferred
                  )}`,
                  "Estimated Deferred Payout With Interest": `$${EstimateNumberFormater(
                    deferred_payout_interest
                  )}`,
                });
              }
            }
          }
        }
      }
      return { interestExportData, interestPdfData };
    } else {
      return { interestExportData: [], interestPdfData: [] };
    }
  }

  // function DecideAREStockForExport(consider, name, payout, order){
  function DecideAREStockForExport(consider, name) {
    try {
      if (
        name === "are_redemption" ||
        name === "are_issuance" ||
        name === "cash_in_lieu_of_stock"
      ) {
        // if(consider){
        //   if(name === "are_redemption"){
        //     return StringFinder(payout, "Aug");
        //   } else if(name === "are_issuance") {
        //     return order == true ? StringFinder(payout, "Jul") : order == false ? StringFinder(payout, "Jan") : false;
        //   } else {
        //     return true;
        //   }
        // }
        // return false;
        return consider;
      }
      return true;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function GenerateExportOverallPayment(data) {
    if (IsArrayNotEmpty(data)) {
      const exportData = [],
        pdfData = [];
      for (const item of data) {
        const {
          cropyear,
          barrels,
          payments,
          year_total_payment,
          consider_are_stock_data,
        } = item;
        if (IsArrayNotEmpty(payments)) {
          for (const list of payments) {
            const {
              payment_month,
              contractOrder,
              remIncentiveCPD_deferred_interest,
            } = list;
            if (IsArrayNotEmpty(Overall_estimate_payment_type_order)) {
              for (const it of Overall_estimate_payment_type_order) {
                const { key: name, label, type, subKey } = it;
                const value = list[name];
                const subValue = list[subKey] || 0;
                const populate = DecideAREStockForExport(
                  consider_are_stock_data,
                  name,
                  payment_month,
                  contractOrder
                );
                const value_access = IsArrayElementsTrue(
                  value != undefined,
                  value != null,
                  value > 0,
                  value != "0.00",
                  value != "0",
                  value != "0.",
                  value != "0.0"
                );
                const access = populate && value_access;
                if (access) {
                  exportData.push({
                    "Crop Year": `${cropyear}`,
                    "Estimated Barrels": ExportNumberConverter(barrels),
                    "Estimated Payment Month": payment_month,
                    "Payment - Equity Type": label,
                    "Estimated Rate":
                      subValue <= 0 ? 0.0 : ExportNumberConverter(subValue),
                    "Estimated Amount": compareString(type, "add")
                      ? ExportNumberConverter(value)
                      : 0.0,
                    "Estimated Deferred Amount": compareString(type, "add")
                      ? 0.0
                      : ExportNumberConverter(value),
                    "Estimated Total Payment (Month Wise)":
                      ExportNumberConverter(remIncentiveCPD_deferred_interest),
                    "Estimated Total Payment (Year Wise)":
                      ExportNumberConverter(year_total_payment),
                  });
                  pdfData.push({
                    "Crop Year": `${cropyear}`,
                    "Estimated Barrels": `$${
                      EstimateNumberFormater(barrels) || "0.00"
                    }`,
                    "Estimated Payment Month": payment_month,
                    "Payment - Equity Type": label,
                    "Estimated Rate":
                      subValue <= 0
                        ? `$0.00`
                        : `$${EstimateNumberFormater(subValue) || "0.00"}`,
                    "Estimated Amount": compareString(type, "add")
                      ? `$${EstimateNumberFormater(value) || "0.00"}`
                      : `$0.00`,
                    "Estimated Deferred Amount": compareString(type, "add")
                      ? `$0.00`
                      : `$${EstimateNumberFormater(value) || "0.00"}`,
                    "Estimated Total Payment (Month Wise)": `$${
                      EstimateNumberFormater(
                        remIncentiveCPD_deferred_interest
                      ) || "0.00"
                    }`,
                    "Estimated Total Payment (Year Wise)": `$${
                      EstimateNumberFormater(year_total_payment) || "0.00"
                    }`,
                  });
                }
              }
            }
          }
        }
      }
      return { exportData, pdfData };
    } else {
      return { exportData: [], pdfData: [] };
    }
  }

  function GetSubTitleArray() {
    try {
      const subTitleArr = [];
      const cont = contract?.contract;
      if (IsStringChecker(cont) && IsArrayNotEmpty(contractOptions)) {
        const found = contractOptions.find((li) =>
          compareString(`${li?.id}`, cont)
        );
        const contractName = found?.name;
        if (IsStringChecker(contractName)) {
          subTitleArr.push({
            line: `Contract: ${contractName}`,
            order: 1,
            key: 1,
          });
        }
      }
      if (IsStringChecker(estimateName)) {
        subTitleArr.push({
          line: `Estimate Name: ${estimateName}`,
          order: 2,
          key: 2,
        });
      }
      // return _.orderBy(subTitleArr, "order", "asc");
      return subTitleArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function EmptyEstimates() {
    set_final_payments_estimate([]);
    set_final_table_estimate([]);
    set_deferred_payment_estimate([]);
    set_export_cash_payment_estimate([]);
    set_export_deferred_payment_estimate([]);
    set_export_interest_payment_estimate([]);
    set_export_overall_payment_estimate([]);
    set_pdf_cash_payment_estimate([]);
    set_pdf_deferred_payment_estimate([]);
    set_pdf_interest_payment_estimate([]);
    set_pdf_overall_payment_estimate([]);
  }

  function DisplayRowsForOverallEstimate(input_arr = [], display_arr = []) {
    try {
      if (IsArrayNotEmpty(input_arr) && IsArrayNotEmpty(display_arr)) {
        const new_arr = input_arr.reduce((accumulator, item, parentGroup) => {
          const {
            key: parentKey,
            cropyear,
            barrels: raw_barrels,
            payments,
            year_total_payment,
          } = item;
          const barrels = EstimateNumberFormater(raw_barrels);
          const formatted_total = EstimateNumberFormater(year_total_payment);
          const even = (parentGroup + 1) % 2 === 0;
          if (IsArrayNotEmpty(payments)) {
            const new_payments_arr = payments.reduce(
              (acc, list, childGroup) => {
                const {
                  key: childKey,
                  payment_month,
                  remIncentiveCPD_deferred_interest: raw_total,
                } = list;
                const foundings = display_arr.filter((it) => {
                  const { oKey } = it;
                  return list[oKey];
                });
                if (IsArrayNotEmpty(foundings)) {
                  const payment_month_total = EstimateNumberFormater(raw_total);
                  foundings.forEach((found) => {
                    const { oKey, oSubKey } = found;
                    const value = EstimateNumberFormater(list[oKey]);
                    if (EstimateNumberValidation(value)) {
                      const subValue = EstimateNumberFormater(list[oSubKey]);
                      const subEven = (childGroup + 1) % 2 === 0;
                      acc.push({
                        ...found,
                        childKey,
                        childGroup,
                        payment_month,
                        value,
                        subValue,
                        subEven,
                        subRowSpan: 0,
                        display_child_columns: false,
                        payment_month_total,
                        parentKey,
                        parentGroup,
                        even,
                        cropyear,
                        barrels,
                        formatted_total,
                        display_parent_columns: false,
                        rowSpan: 0,
                      });
                      return acc;
                    }
                    return acc;
                  });
                }
                return acc;
              },
              []
            );
            if (IsArrayNotEmpty(new_payments_arr)) {
              accumulator = [...accumulator, ...new_payments_arr];
              return accumulator;
            } else {
              return accumulator;
            }
          } else {
            return accumulator;
          }
        }, []);
        const final_arr = [];
        if (IsArrayNotEmpty(new_arr)) {
          const parent_grouped = _.groupBy(new_arr, "cropyear");
          const parent_grouped_arr = Object.values(parent_grouped);
          if (IsArrayNotEmpty(parent_grouped_arr)) {
            parent_grouped_arr.forEach((list) => {
              if (IsArrayNotEmpty(list)) {
                const main_arr = list.map((item, ind) => ({
                  ...item,
                  display_parent_columns: ind == 0,
                  rowSpan: ind == 0 ? list.length : 0,
                }));
                const child_grouped = _.groupBy(main_arr, "payment_month");
                const child_grouped_arr = Object.values(child_grouped);
                if (IsArrayNotEmpty(child_grouped_arr)) {
                  child_grouped_arr.forEach((li) => {
                    if (IsArrayNotEmpty(li)) {
                      const sub_arr = li.map((it, i) => {
                        return {
                          ...it,
                          display_child_columns: i == 0,
                          subRowSpan: i == 0 ? li.length : 0,
                        };
                      });
                      sub_arr.forEach((i) => final_arr.push(i));
                    }
                  });
                }
              }
            });
          }
          return final_arr;
        }
        return [];
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  useEffect(() => {
    set_loading_deferred_payment_estimate(true);
    EmptyEstimates();
    setTimeout(() => {
      PaymentReport();
      set_loading_deferred_payment_estimate(false);
    }, 1000);
  }, [calculateEstimate]);
  const SubTitleArray = GetSubTitleArray();
  return (
    <>
      <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
        <h3 className="mob-fw-bold py-3">
          {_.get(
            moreLangs,
            `${resolvedLanguage}.home_page.payment_estimator_report`,
            "Payment Estimator - Report"
          )}
        </h3>
        {loading_deferred_payment_estimate ? (
          <div className="row m-2">
            <div className="col">
              <div className="p-2 text-center border-1 border-radius-6 mt-2">
                <WhiteCircleLoader invert={true} />{" "}
                {`${_.get(
                  moreLangs,
                  `${resolvedLanguage}.noDataErrorMsg`,
                  "Payment Estimate Data is Loading. Please Wait."
                )}`}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${
                false ? "d-lg-none" : "desktop-accordion-table"
              }`}
            >
              <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts table-width-fix">
                <Accordion activeKey={active_accordion || ""}>
                  <Accordion.Item eventKey={cash_payment_title}>
                    <Accordion.Header
                      onClick={() => HandleAccordionClick(cash_payment_title)}
                    >
                      Cash Payment Estimate
                    </Accordion.Header>
                    <Accordion.Body>
                      {IsArrayNotEmpty(deferred_payment_estimate) ? (
                        <>
                          <div className="row pe-2 pe-lg-4">
                            <div className="col-md-6 d-none d-md-block"></div>
                            <div className="d-none d-md-block col-md-6">
                              <div className="float-end">
                                <span>
                                  <PdfFile
                                    tooltip={"Print"}
                                    pdfData={pdf_cash_payment_estimate}
                                    fileName="CashPaymentEstimate"
                                    FileTitle="Cash Payment Estimate"
                                    SubTitleArray={SubTitleArray}
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="csv"
                                    exportData={export_cash_payment_estimate}
                                    fileName="CashPaymentEstimate"
                                    formatArray={export_cash_estimate_format}
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="xlsx"
                                    exportData={export_cash_payment_estimate}
                                    fileName="CashPaymentEstimate"
                                    formatArray={export_cash_estimate_format}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <Table
                                responsive
                                className="payment-estimator-table cropyear-sticky-table"
                              >
                                <thead className="pay-est-head">
                                  <tr className="pay-est-row">
                                    <th className="pay-est-bold cropyear-sticky-column">
                                      Crop Year
                                    </th>
                                    <th className="pay-est-bold">Barrels</th>
                                    <th className="pay-est-bold">Month</th>
                                    <th className="pay-est-bold right-align">
                                      Payment Rate
                                    </th>
                                    <th className="pay-est-bold right-align">
                                      Continuous Pool Payment
                                    </th>
                                    <th className="pay-est-bold right-align">
                                      Incentives
                                    </th>
                                    <th className="pay-est-bold right-align">
                                      Total Payment
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="pay-est-body">
                                  {deferred_payment_estimate.map(
                                    (item, index) => {
                                      const {
                                        key: parentKey,
                                        cropyear,
                                        barrels: raw_barrels,
                                        payments,
                                      } = item;
                                      const barrels =
                                        EstimateNumberFormater(raw_barrels);
                                      if (IsArrayNotEmpty(payments)) {
                                        return (
                                          <Fragment key={parentKey}>
                                            {payments.map((list, ind) => {
                                              const {
                                                key,
                                                month,
                                                payment_rate: raw_payment_rate,
                                                continuous_pool_distribution:
                                                  raw_cpd,
                                                incentive: raw_incentive,
                                                cash_payment: raw_cash_payment,
                                              } = list;
                                              const cash_payment =
                                                EstimateNumberFormater(
                                                  raw_cash_payment
                                                );
                                              const incentive =
                                                EstimateNumberFormater(
                                                  raw_incentive
                                                );
                                              const continuous_pool_distribution =
                                                EstimateNumberFormater(raw_cpd);
                                              const payment_rate =
                                                EstimateNumberFormater(
                                                  raw_payment_rate
                                                );
                                              return (
                                                <Fragment key={key}>
                                                  <tr className="pay-est-row">
                                                    {ind === 0 && (
                                                      <>
                                                        <th
                                                          className={`pay-est-all-center-color cropyear-sticky-column`}
                                                          rowSpan={4}
                                                        >
                                                          {cropyear}
                                                        </th>
                                                        <th
                                                          className={`pay-est-all-center ${
                                                            index % 2 !== 0
                                                              ? "bg-white"
                                                              : ""
                                                          }`}
                                                          rowSpan={4}
                                                        >
                                                          {barrels}
                                                        </th>
                                                      </>
                                                    )}
                                                    <th className="pay-est-data">
                                                      {month}
                                                    </th>
                                                    <td className="pay-est-data right-align">
                                                      {`$${payment_rate}`}
                                                    </td>
                                                    <td className="pay-est-data right-align">
                                                      {`$${continuous_pool_distribution}`}
                                                    </td>
                                                    <td className="pay-est-data right-align">
                                                      {`$${incentive}`}
                                                    </td>
                                                    <td className="pay-est-data right-align">
                                                      {`$${cash_payment}`}
                                                    </td>
                                                  </tr>
                                                </Fragment>
                                              );
                                            })}
                                          </Fragment>
                                        );
                                      } else {
                                        return (
                                          <Fragment key={parentKey}></Fragment>
                                        );
                                      }
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row m-2">
                          <div className="col">
                            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                              <i className="fa fa-info-circle pe-2"></i>
                              {`${_.get(
                                moreLangs,
                                `${resolvedLanguage}.noDataErrorMsg`,
                                "No Data found to show under Cash Payment estimate"
                              )}`}
                            </div>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={deferred_payment_title}>
                    <Accordion.Header
                      onClick={() =>
                        HandleAccordionClick(deferred_payment_title)
                      }
                    >
                      Payment Estimate with Payment Deferrals
                    </Accordion.Header>
                    <Accordion.Body>
                      {IsArrayNotEmpty(deferred_payment_estimate) ? (
                        <>
                          <div className="row pe-2 pe-lg-4">
                            <div className="col-md-6 d-none d-md-block"></div>
                            <div className="d-none d-md-block col-md-6">
                              <div className="float-end">
                                <span>
                                  <PdfFile
                                    tooltip={"Print"}
                                    pdfData={pdf_deferred_payment_estimate}
                                    fileName="PaymentEstimateWithPaymentDeferrals"
                                    FileTitle="Payment Estimate with Payment Deferrals"
                                    SubTitleArray={SubTitleArray}
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="csv"
                                    exportData={
                                      export_deferred_payment_estimate
                                    }
                                    fileName="PaymentEstimateWithPaymentDeferrals"
                                    formatArray={
                                      export_deferred_estimate_format
                                    }
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="xlsx"
                                    exportData={
                                      export_deferred_payment_estimate
                                    }
                                    fileName="PaymentEstimateWithPaymentDeferrals"
                                    formatArray={
                                      export_deferred_estimate_format
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <div className="row m-2">
                                <div className="col">
                                  <Table
                                    responsive
                                    className="payment-estimator-table cropyear-sticky-table"
                                  >
                                    <thead className="pay-est-head">
                                      <tr className="pay-est-row">
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold cropyear-sticky-column"
                                        >
                                          Crop Year
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Month
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Continuous Pool Payment
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Deferrel Period (months)
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Deferrel %
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Amount of Payment Deferred
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Net Payment after Deferrel
                                        </th>
                                        <th
                                          className="pay-est-bold"
                                          colSpan={2}
                                        >
                                          List of Deferred Payouts
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Deferred Payout
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Total Payment
                                        </th>
                                      </tr>
                                      <tr className="pay-est-row">
                                        <th className="pay-est-bold col-4">
                                          Deferred Payment From
                                        </th>
                                        <th className="pay-est-bold right-align">
                                          Deferred Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="pay-est-body">
                                      {deferred_payment_estimate.map((item) => {
                                        const {
                                          key: parentKey,
                                          cropyear,
                                          payments,
                                        } = item;
                                        if (IsArrayNotEmpty(payments)) {
                                          const rowSpan =
                                            GetMaxRowSpan(payments);
                                          return (
                                            <Fragment key={parentKey}>
                                              {payments.map((list, ind) => {
                                                const {
                                                  key,
                                                  month,
                                                  continuous_pool_distribution:
                                                    raw_cpd,
                                                  deferrel_period,
                                                  deferrel_percent,
                                                  deferred_amount:
                                                    raw_deferred_amount,
                                                  remaining_amount:
                                                    raw_remaining_amount,
                                                  deferrel_payouts,
                                                  deferred_payout:
                                                    raw_deferred_payout,
                                                  remCPD_deferred: raw_total,
                                                } = list;
                                                const total =
                                                  EstimateNumberFormater(
                                                    raw_total
                                                  );
                                                const deferred_payout =
                                                  EstimateNumberFormater(
                                                    raw_deferred_payout
                                                  );
                                                const deferred_amount =
                                                  EstimateNumberFormater(
                                                    raw_deferred_amount
                                                  );
                                                const remaining_amount =
                                                  EstimateNumberFormater(
                                                    raw_remaining_amount
                                                  );
                                                const continuous_pool_distribution =
                                                  EstimateNumberFormater(
                                                    raw_cpd
                                                  );
                                                const isSingleRow =
                                                  IsArrayNotEmpty(
                                                    deferrel_payouts
                                                  )
                                                    ? deferrel_payouts.length ===
                                                      1
                                                      ? true
                                                      : false
                                                    : true;
                                                const subRowSpan = isSingleRow
                                                  ? 1
                                                  : deferrel_payouts.length;
                                                return (
                                                  <Fragment key={key}>
                                                    {deferrel_payouts.map(
                                                      (li, num) => {
                                                        const {
                                                          deferrel_label,
                                                          amount,
                                                        } = li;
                                                        const deferred_amount_label =
                                                          EstimateNumberFormater(
                                                            amount
                                                          );
                                                        return (
                                                          <tr
                                                            key={num}
                                                            className={`pay-est-row ${
                                                              rowSpan > 4
                                                                ? "pay-est-vertical"
                                                                : ""
                                                            }`}
                                                          >
                                                            {ind === 0 &&
                                                              num === 0 && (
                                                                <th
                                                                  className={`pay-est-all-center-color cropyear-sticky-column`}
                                                                  rowSpan={
                                                                    rowSpan
                                                                  }
                                                                >
                                                                  {cropyear}
                                                                </th>
                                                              )}
                                                            {num === 0 && (
                                                              <>
                                                                <th
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {month}
                                                                </th>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${continuous_pool_distribution}`}
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {
                                                                    deferrel_period
                                                                  }
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {deferrel_percent ==
                                                                  0
                                                                    ? 0
                                                                    : `${deferrel_percent}%`}
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${deferred_amount}`}
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${remaining_amount}`}
                                                                </td>
                                                              </>
                                                            )}
                                                            <td className="pay-est-data">
                                                              {deferrel_label}
                                                            </td>
                                                            <td className="pay-est-data right-align">{`$${deferred_amount_label}`}</td>
                                                            {num === 0 && (
                                                              <>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${deferred_payout}`}
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${total}`}
                                                                </td>
                                                              </>
                                                            )}
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </Fragment>
                                                );
                                              })}
                                            </Fragment>
                                          );
                                        } else {
                                          return (
                                            <Fragment
                                              key={parentKey}
                                            ></Fragment>
                                          );
                                        }
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row m-2">
                          <div className="col">
                            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                              <i className="fa fa-info-circle pe-2"></i>
                              {`${_.get(
                                moreLangs,
                                `${resolvedLanguage}.noDataErrorMsg`,
                                "No Data found to show under Deferred Payment estimate"
                              )}`}
                            </div>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={interest_payment_title}>
                    <Accordion.Header
                      onClick={() =>
                        HandleAccordionClick(interest_payment_title)
                      }
                    >
                      Interest Payment Estimate
                    </Accordion.Header>
                    <Accordion.Body>
                      {IsArrayNotEmpty(deferred_payment_estimate) ? (
                        <>
                          <div className="row pe-2 pe-lg-4">
                            <div className="col-md-6 d-none d-md-block"></div>
                            <div className="d-none d-md-block col-md-6">
                              <div className="float-end">
                                <span>
                                  <PdfFile
                                    tooltip={"Print"}
                                    pdfData={pdf_interest_payment_estimate}
                                    fileName="InterestPaymentEstimate"
                                    FileTitle="Interest Payment Estimate"
                                    SubTitleArray={SubTitleArray}
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="csv"
                                    exportData={
                                      export_interest_payment_estimate
                                    }
                                    fileName="InterestPaymentEstimate"
                                    formatArray={
                                      export_interest_estimate_format
                                    }
                                  />
                                </span>
                                <span>
                                  <ExportFile
                                    downloadFormate="xlsx"
                                    exportData={
                                      export_interest_payment_estimate
                                    }
                                    fileName="InterestPaymentEstimate"
                                    formatArray={
                                      export_interest_estimate_format
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <div className="row m-2">
                                <div className="col">
                                  <Table
                                    responsive
                                    className="payment-estimator-table cropyear-sticky-table"
                                  >
                                    <thead className="pay-est-head">
                                      <tr className="pay-est-row">
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold cropyear-sticky-column"
                                        >
                                          Crop Year
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Month
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Deferrel Period (months)
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold"
                                        >
                                          Interest Rate %
                                        </th>
                                        <th
                                          className="pay-est-bold"
                                          colSpan={4}
                                        >
                                          List of Deferred Payouts
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Total Interest
                                        </th>
                                        <th
                                          rowSpan={2}
                                          className="pay-est-bold right-align"
                                        >
                                          Deferred Payout With Interest
                                        </th>
                                      </tr>
                                      <tr className="pay-est-row">
                                        <th className="pay-est-bold">
                                          Deferred Payment From
                                        </th>
                                        <th className="pay-est-bold right-align">
                                          Deferred Amount
                                        </th>
                                        <th className="pay-est-bold right-align">
                                          Calculated Inerest Rate
                                        </th>
                                        <th className="pay-est-bold right-align">
                                          Interest
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="pay-est-body">
                                      {deferred_payment_estimate.map((item) => {
                                        const {
                                          key: parentKey,
                                          cropyear,
                                          payments,
                                          deferrel_interest,
                                        } = item;
                                        if (IsArrayNotEmpty(payments)) {
                                          const rowSpan =
                                            GetMaxRowSpan(payments);
                                          return (
                                            <Fragment key={parentKey}>
                                              {payments.map((list, ind) => {
                                                const {
                                                  key,
                                                  month,
                                                  deferrel_period,
                                                  deferrel_payouts,
                                                  total_interest_deferred:
                                                    raw_total_interest,
                                                  deferred_payout_interest:
                                                    raw_deferred_payout_interest,
                                                } = list;
                                                const total_interest_deferred =
                                                  EstimateNumberFormater(
                                                    raw_total_interest
                                                  );
                                                const deferred_payout_interest =
                                                  EstimateNumberFormater(
                                                    raw_deferred_payout_interest
                                                  );
                                                const isSingleRow =
                                                  IsArrayNotEmpty(
                                                    deferrel_payouts
                                                  )
                                                    ? deferrel_payouts.length ===
                                                      1
                                                      ? true
                                                      : false
                                                    : true;
                                                const subRowSpan = isSingleRow
                                                  ? 1
                                                  : deferrel_payouts.length;
                                                const even =
                                                  (ind + 1) % 2 === 0;
                                                return (
                                                  <Fragment key={key}>
                                                    {deferrel_payouts.map(
                                                      (li, num) => {
                                                        const {
                                                          deferrel_label,
                                                          amount,
                                                          interest_rate:
                                                            raw_interest_rate,
                                                          interest,
                                                        } = li;
                                                        const deferred_amount_label =
                                                          EstimateNumberFormater(
                                                            amount
                                                          );
                                                        const interest_label =
                                                          EstimateNumberFormater(
                                                            interest
                                                          );
                                                        const interest_rate =
                                                          parseFloat(
                                                            raw_interest_rate
                                                          ).toFixed(4);
                                                        const new_deferrel_label = deferrel_label?.split("-")?.[0] || "-";
                                                        return (
                                                          <tr
                                                            key={num}
                                                            className={`pay-est-row ${
                                                              even
                                                                ? "eq-row-even"
                                                                : "eq-row-odd"
                                                            } ${
                                                              rowSpan > 4
                                                                ? "pay-est-vertical"
                                                                : ""
                                                            }`}
                                                          >
                                                            {ind === 0 &&
                                                              num === 0 && (
                                                                <th
                                                                  className={`cropyear-sticky-column pay-est-all-center-color`}
                                                                  rowSpan={
                                                                    rowSpan
                                                                  }
                                                                >
                                                                  {cropyear}
                                                                </th>
                                                              )}
                                                            {num === 0 && (
                                                              <>
                                                                <th
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {month}
                                                                </th>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {
                                                                    deferrel_period
                                                                  }
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data"
                                                                >
                                                                  {deferrel_interest ==
                                                                  0
                                                                    ? 0
                                                                    : `${deferrel_interest}%`}
                                                                </td>
                                                              </>
                                                            )}
                                                            <td className="pay-est-data">
                                                              {new_deferrel_label}
                                                            </td>
                                                            <td className="pay-est-data right-align">{`$${deferred_amount_label}`}</td>
                                                            <td className="pay-est-data right-align">
                                                              {interest_rate <=
                                                              0
                                                                ? "-"
                                                                : `$${interest_rate}`}
                                                            </td>
                                                            <td className="pay-est-data right-align">{`$${interest_label}`}</td>
                                                            {num === 0 && (
                                                              <>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${total_interest_deferred}`}
                                                                </td>
                                                                <td
                                                                  rowSpan={
                                                                    subRowSpan
                                                                  }
                                                                  className="pay-est-data right-align"
                                                                >
                                                                  {`$${deferred_payout_interest}`}
                                                                </td>
                                                              </>
                                                            )}
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </Fragment>
                                                );
                                              })}
                                            </Fragment>
                                          );
                                        } else {
                                          return (
                                            <Fragment
                                              key={parentKey}
                                            ></Fragment>
                                          );
                                        }
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row m-2">
                          <div className="col">
                            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                              <i className="fa fa-info-circle pe-2"></i>
                              {`${_.get(
                                moreLangs,
                                `${resolvedLanguage}.noDataErrorMsg`,
                                "No Data found to show under Interest Payment estimate"
                              )}`}
                            </div>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <h4 className="fw-bold">
                  {_.get(
                    moreLangs,
                    `${resolvedLanguage}.home_page.payment_estimate`,
                    "Payment Estimate (Overall):"
                  )}
                </h4>
              </div>
            </div>
            {IsArrayNotEmpty(final_payments_estimate) &&
            IsArrayNotEmpty(final_table_estimate) ? (
              <>
                <div className="row pe-2">
                  <div className="col-md-6 d-none d-md-block"></div>
                  <div className="d-none d-md-block col-md-6">
                    <div className="float-end">
                      <span>
                        <PdfFile
                          tooltip={"Print"}
                          pdfData={pdf_overall_payment_estimate}
                          SubTitleArray={[
                            ...SubTitleArray,
                            {
                              line: `Estimated Total Payment: ${EstimatedOverallTotal(
                                final_payments_estimate
                              )}`,
                              order: 3,
                              key: 3,
                            },
                          ]}
                          fileName="OverallPaymentEstimate"
                          FileTitle="Overall Payment Estimate"
                        />
                      </span>
                      <span>
                        <ExportFile
                          downloadFormate="csv"
                          exportData={export_overall_payment_estimate}
                          fileName="OverallPaymentEstimate"
                          formatArray={export_overall_estimate_format}
                        />
                      </span>
                      <span>
                        <ExportFile
                          downloadFormate="xlsx"
                          exportData={export_overall_payment_estimate}
                          fileName="OverallPaymentEstimate"
                          formatArray={export_overall_estimate_format}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <Table
                      responsive
                      className="payment-estimator-table cropyear-sticky-table"
                    >
                      <thead className="pay-est-head">
                        <tr className="pay-est-row">
                          <th
                            rowSpan={2}
                            className="pay-est-bold cropyear-sticky-column b-rad-lt"
                          >
                            Crop Year
                          </th>
                          <th rowSpan={2} className="pay-est-bold">
                            Barrels
                          </th>
                          <th rowSpan={2} className="pay-est-bold">
                            Payment Month
                          </th>
                          <th rowSpan={2} className="pay-est-bold">
                            Payment - Equity Type
                          </th>
                          <th className="pay-est-bold" colSpan={2}>
                            Payment
                          </th>
                          <th rowSpan={2} className="pay-est-bold right-align">
                            Total Payment (Month wise)
                          </th>
                          <th
                            rowSpan={2}
                            className="pay-est-bold b-rad-rt right-align"
                          >
                            Total Payment (Year wise)
                          </th>
                        </tr>
                        <tr className="pay-est-row">
                          <th className="pay-est-bold right-align">Rate</th>
                          <th className="pay-est-bold right-align">Amount</th>
                        </tr>
                      </thead>
                      <tbody className="pay-est-body">
                        {final_table_estimate.map((item, index) => {
                          const {
                            type = "add",
                            label = "",
                            payment_month = "",
                            value = "",
                            subValue = "",
                            subEven = false,
                            subRowSpan = 0,
                            display_child_columns = false,
                            even = false,
                            cropyear = "",
                            barrels = "",
                            formatted_total = "",
                            display_parent_columns = false,
                            rowSpan = 0,
                            payment_month_total = 0,
                          } = item;
                          return (
                            <Fragment key={index}>
                              <tr className="pay-est-row">
                                {display_parent_columns && (
                                  <>
                                    <th
                                      className={`pay-est-all-center-color cropyear-sticky-column`}
                                      rowSpan={rowSpan}
                                    >
                                      {cropyear}
                                    </th>
                                    <th
                                      className={`pay-est-all-center ${
                                        even ? "row-even" : "row-odd"
                                      }`}
                                      rowSpan={rowSpan}
                                    >
                                      {barrels}
                                    </th>
                                  </>
                                )}
                                {display_child_columns && (
                                  <th
                                    className={`pay-est-all-center ${
                                      subEven ? "row-even" : "row-odd"
                                    }`}
                                    rowSpan={subRowSpan}
                                  >
                                    {payment_month}
                                  </th>
                                )}
                                <td className="pay-est-data">{label}</td>
                                <td className="pay-est-data right-align">
                                  {subValue ? `$${subValue}` : ""}
                                </td>
                                <td
                                  className={`pay-est-data right-align ${
                                    type === "sub" ? `red_color ` : ""
                                  }`}
                                >
                                  {type === "sub" ? `(` : ""}
                                  {`$${value}`}
                                  {type === "sub" ? `)` : ""}
                                </td>
                                {display_child_columns && (
                                  <th
                                    className={`pay-est-all-center right-align ${
                                      subEven ? "row-even" : "row-odd"
                                    }`}
                                    rowSpan={subRowSpan}
                                  >
                                    {`$${payment_month_total || value}`}
                                  </th>
                                )}
                                {display_parent_columns && (
                                  <th
                                    className={`pay-est-all-center right-align ${
                                      even ? "row-even" : "row-odd"
                                    }`}
                                    rowSpan={rowSpan}
                                  >
                                    {`$${formatted_total}`}
                                  </th>
                                )}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                      <tfoot className="pay-est-foot">
                        <tr className="pay-est-row">
                          <th colSpan={6} className="pay-est-bold b-rad-lb">
                            Estimated Total Payment
                          </th>
                          <th colSpan={2} className="pay-est-bold b-rad-rb">
                            {EstimatedOverallTotal(final_payments_estimate)}
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </div>
              </>
            ) : (
              <div className="row m-2">
                <div className="col">
                  <div className="p-2 red_color bg-white text-center border-1 border-radius-6 mt-2">
                    <i className="fa fa-info-circle pe-2"></i>
                    {`${_.get(
                      moreLangs,
                      `${resolvedLanguage}.noDataErrorMsg`,
                      "No Data found to show under Overall Payment Estimate"
                    )}`}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentEstimatorCalculationDetail;
