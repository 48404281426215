import React from "react";
import BreadCrumb from "../../global/breadCrumb";
import LeftMenu from "../../global/leftMenu";
import LandingPageCtaContentBlock from "../../global/landingPageCtaContentBlock";

const CranberryInstituteDetails = (props) => {
    return <>
        <div className="row">
            <div className="row">
                <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
                    <BreadCrumb {...props} />
                </div>
            </div>
            <div className="row">
                <div className="d-none d-lg-block col-lg-2 hidden_print">
                    <LeftMenu {...props} slug="news-and-events" />
                </div>
                <div className="col-12 col-lg-10 cranberry-institute">
                    <LandingPageCtaContentBlock
                        slug={"cranberry-institute"}
                        {...props}
                    />
                </div>
            </div>
        </div>

    </>
};

export default CranberryInstituteDetails;
