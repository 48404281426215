const MapColors = [
  {
    ReportOption: "Yield",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: 0.0,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0.0001,
        ValueTo: 50.0,
        HEX: "#FFFFFF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 50.0001,
        ValueTo: 100.0,
        HEX: "#FFDDD1",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 100.0001,
        ValueTo: 150.0,
        HEX: "#FFBBA6",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 150.0001,
        ValueTo: 200.0,
        HEX: "#FF9B80",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 200.0001,
        ValueTo: 250.0,
        HEX: "#FF7A5C",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 250.0001,
        ValueTo: 300.0,
        HEX: "#FC593D",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 300.0001,
        ValueTo: 350.0,
        HEX: "#F23A22",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType9`,
        ValueFrom: 350.0001,
        ValueTo: Infinity,
        HEX: "#E60000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
    ],
  },
  {
    ReportOption: "PercentDryTrash",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 2.0,
        HEX: "#10F500",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 2.0001,
        ValueTo: 4.0,
        HEX: "#80F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 4.0001,
        ValueTo: 6.0,
        HEX: "#D2F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 6.0001,
        ValueTo: 8.0,
        HEX: "#FACC00",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 8.0001,
        ValueTo: 10.0,
        HEX: "#FC7600",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 10.0001,
        ValueTo: 100.0,
        HEX: "#F50000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "PercentMoisture",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 1.0,
        HEX: "#FFFFFF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 1.0001,
        ValueTo: 2.0,
        HEX: "#BACBE8",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 2.0001,
        ValueTo: 3.0,
        HEX: "#7F9FD4",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 3.0001,
        ValueTo: 4.0,
        HEX: "#4B77BD",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 4.0001,
        ValueTo: 5.0,
        HEX: "#2255A8",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 5.0001,
        ValueTo: 100.0,
        HEX: "#003994",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "PercentPoor",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 3.0,
        HEX: "#10F500",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 3.0001,
        ValueTo: 6.0,
        HEX: "#80F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 6.0001,
        ValueTo: 9.0,
        HEX: "#D2F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 9.0001,
        ValueTo: 12.0,
        HEX: "#FACC00",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 12.0001,
        ValueTo: 20.0,
        HEX: "#FC7600",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 20.0001,
        ValueTo: 100.0,
        HEX: "#F50000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "PercentClassOne",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 3.0,
        HEX: "#E60000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 3.0001,
        ValueTo: 6.0,
        HEX: "#F54A33",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 6.0001,
        ValueTo: 9.0,
        HEX: "#FF7A66",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 9.0001,
        ValueTo: 12.0,
        HEX: "#FFA9A1",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 12.0001,
        ValueTo: 15.0,
        HEX: "#FFC8BF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 15.0001,
        ValueTo: 18.0,
        HEX: "#FFDBBF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 18.0001,
        ValueTo: 21.0,
        HEX: "#FFEDBF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType9`,
        ValueFrom: 21.0001,
        ValueTo: 100,
        HEX: "#FFFFBF",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType10`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "PercentUsable",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: 0,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0.0001,
        ValueTo: 70.0,
        HEX: "#F50000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 70.0001,
        ValueTo: 75.0,
        HEX: "#FC6900",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 75.0001,
        ValueTo: 80.0,
        HEX: "#FCB100",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 80.0001,
        ValueTo: 85.0,
        HEX: "#F5F500",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 85.0001,
        ValueTo: 90.0,
        HEX: "#BAF700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 90.0001,
        ValueTo: 95.0,
        HEX: "#73F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 95.0001,
        ValueTo: 100.0,
        HEX: "#10F500",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType9`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "Firmness",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: 250.0,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 250.0001,
        ValueTo: 450.0,
        HEX: "#F50000",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 450.0001,
        ValueTo: 500.0,
        HEX: "#FC7600",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 500.0001,
        ValueTo: 550.0,
        HEX: "#FACC00",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 550.0001,
        ValueTo: 600.0,
        HEX: "#D2F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 600.0001,
        ValueTo: 650.0,
        HEX: "#80F700",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 650.0001,
        ValueTo: Infinity,
        HEX: "#10F500",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
    ],
  },
  {
    ReportOption: "Percent>1/2",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 50.0,
        HEX: "#C2523C",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType3`,
        ValueFrom: 50.0001,
        ValueTo: 60.0,
        HEX: "#EDA113",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType4`,
        ValueFrom: 60.0001,
        ValueTo: 70.0,
        HEX: "#FFFF00",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType5`,
        ValueFrom: 70.0001,
        ValueTo: 80.0,
        HEX: "#00DB00",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType6`,
        ValueFrom: 80.0001,
        ValueTo: 90.0,
        HEX: "#20998F",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType7`,
        ValueFrom: 90.0001,
        ValueTo: 100.0,
        HEX: "#0B2C7A",
        BorderRGBA:"light",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType8`,
        ValueFrom: 100.0001,
        ValueTo: Infinity,
        HEX: "transparent",
        BorderRGBA:"bright",
        BorderWidth:"thick",
      },
    ],
  },
  {
    ReportOption: "PSPSampleCollection",
    MapColor: [
      {
        ColorType: `ColorType1`,
        ValueFrom: null,
        ValueTo: null,
        HEX: "#808080",
        BorderRGBA:"grey-o",
        BorderWidth:"thin",
      },
      {
        ColorType: `ColorType2`,
        ValueFrom: 0,
        ValueTo: 1000,
        HEX: "#FF72F0",
        BorderRGBA:"pink-o",
        BorderWidth:"thick",
      }
    ],
  },
];

export const ReportOptionsKeyMap = {
  Yield: "Yield",
  Barrels: "TotalBarrels",
  PercentDryTrash: "PercentDryTrash",
  Firmness: "AverageFirmness",
  "Percent>1/2": "PercentGreaterThanHalf",
  PercentUsable: "AveragePercentUsable",
  PercentClassOne: "PercentClass1",
  PercentPoor: "AveragePercentPoor",
  PercentMoisture: "PercentLowestMoist",
  PSPSampleCollection: "PSP Sample Collection",
};

export default Object.freeze(MapColors);